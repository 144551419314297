import httpClient from '../api/client';

export const downloadJSON = async (
  endpoint: string,
  certId: string,
): Promise<void> => {
  try {
    const json = await httpClient.get<any>(endpoint);

    const link = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(json),
    )}`;
    const downloadAnchorNode = document.createElement('a');

    downloadAnchorNode.setAttribute('href', link);
    downloadAnchorNode.setAttribute('download', `${certId}.json`);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  } catch (err) {
    console.log(err);
  }
};
