import React from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import { Input } from '../atoms/inputs/Input';
import { Label } from '../atoms/labels/Label';
import { Button } from '../atoms/buttons/Button';
import { Paragraph } from '../atoms/paragraphs/Paragraph';

export const Form = styled.form`
  width: 100%;
  height: 100%;
  max-width: 320px;
  min-height: 160px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  border-radius: 10px;
  font-size: 14px;
`;

const ReCAPTCHAWrapper = styled.div`
  margin: 15px 10px;
`;

export type LoginFormTypes = {
  handleSubmit(event: React.SyntheticEvent): void;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
  handleRecaptcha(token: string | null): void;
  error: boolean;
  errorMessage: string;
};

const LoginForm: React.FC<LoginFormTypes> = (props: LoginFormTypes) => {
  return (
    <Form onSubmit={props.handleSubmit}>
      <Label>Enter password:</Label>
      <Input
        type="password"
        name="form-password"
        placeholder="Password"
        onChange={props.handleChange}
        required={true}
      />
      <ReCAPTCHAWrapper>
        <ReCAPTCHA
          sitekey={String(process.env.REACT_APP_RECAPTCHA_CLIENT_KEY)}
          onChange={props.handleRecaptcha}
        />
      </ReCAPTCHAWrapper>
      <Button type="submit">Login</Button>
      <Paragraph>{props.error ? props.errorMessage : ''}</Paragraph>
    </Form>
  );
};

export default LoginForm;
