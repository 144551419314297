import styled from 'styled-components';

export const Input = styled.input`
  max-width: 50%;
  margin: 20px auto 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  text-align: center;
`;
