import React from 'react';
import '@blockcerts/blockcerts-verifier/dist/main';
import { Container } from '../pages/Home';
import { DownloadButton } from '../atoms/buttons/DownloadButton';
import { useDownloadJson } from '../../hooks/useDownloadJson';

type ViewerProps = {
  jsonLink: string;
  pdfLink: string;
  certId: string;
  pdfFlag: boolean;
};

const Viewer: React.FC<ViewerProps> = (props: ViewerProps) => {
  useDownloadJson(props.jsonLink, props.certId);

  React.useEffect(() => {
    document.title = 'Viewer';
  });

  return (
    <Container className="viewer-container">
      {props.pdfFlag && (
        <DownloadButton
          href={`${process.env.REACT_APP_BACKEND_URL}/${props.pdfLink}`}
          download
          target="_blank"
        >
          Download PDF
        </DownloadButton>
      )}
      <blockcerts-verifier
        display-mode="fullscreen"
        disable-auto-verify
        allow-download
        show-metadata
        src={`${process.env.REACT_APP_BACKEND_URL}/${props.jsonLink}`}
      />
    </Container>
  );
};

export default Viewer;
