import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import OldViewer from './components/pages/old/Viewer';
import ViewerManager from './components/pages/ViewerManager';

const Routes: React.FC = () => {
  return (
    <Router basename={process.env.BASE_URL}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          path="/viewer/issuer/:issuerId/cert/:certId"
          component={ViewerManager}
        />
        <Route path="/viewer/:id" component={OldViewer} />
      </Switch>
    </Router>
  );
};

export default Routes;
