import styled from 'styled-components';

export const DownloadButton = styled.a`
  position: absolute;
  bottom: 40px;
  right: 4%;
  z-index: 400;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  box-shadow: 0 2px 8px -1px rgba(10, 22, 50, 0.24);
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  padding: 10px 40px;
  background: #2ab27b;
  color: #fff;
`;
