import React from 'react';
import { useHistory } from 'react-router';
import { downloadJSON } from '../helpers/downloadJson';

export const useDownloadJson = (jsonLink: string, certId: string): void => {
  const [downloaded, setDownloaded] = React.useState<boolean>(false);
  const history = useHistory();

  React.useEffect(() => {
    if (history.location.search === '?format=json' && !downloaded) {
      downloadJSON(jsonLink, certId);
      setDownloaded(true);
    }
  }, []);
};
