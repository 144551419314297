import httpClient from '../api/client';

export const getFlags = async (
  issuerId: string,
  certId: string,
): Promise<{
  authFlag: boolean;
  pdfFlag: boolean;
}> => {
  try {
    const endpoint = `issuer/${issuerId}/cert/${certId}/flags`;
    type ResponseBody = {
      pdfFlag: boolean;
      authFlag: boolean;
    };

    const json = await httpClient.get<ResponseBody>(endpoint);
    return {
      ...json,
    };
  } catch (err) {
    console.log(err);
    return {
      authFlag: false,
      pdfFlag: false,
    };
  }
};
