import React from 'react';
import { useParams } from 'react-router';
import { ViewerQueryParam } from '../../types/ViewerQueryParam';
import { Container } from './Home';
import Login from '../standalones/Login';
import Viewer from '../standalones/Viewer';
import { getFlags } from '../../helpers/getFlags';
import { PdfStatusEnum } from '../../types/pdfEnums';

const ViewerManager: React.FC = () => {
  const { issuerId, certId } = useParams<ViewerQueryParam>();
  const [pdfFlag, setPdfFlag] = React.useState<boolean>(false);
  const [authFlag, setAuthFlag] = React.useState<boolean>(false);

  React.useEffect(() => {
    getFlags(issuerId, certId)
      .then(({ authFlag, pdfFlag }) => {
        setPdfFlag(pdfFlag);
        setAuthFlag(authFlag);
      })
      .catch(() => {
        setPdfFlag(Boolean(PdfStatusEnum.EMPTY));
        setAuthFlag(false);
      });
  }, [certId]);

  const jsonLink: string = `issuer/${issuerId}/cert/${certId}/json`;
  const pdfLink: string = `issuer/${issuerId}/cert/${certId}/pdf`;

  return (
    <Container>
      {authFlag ? (
        <Login issuerId={issuerId} certId={certId} pdfFlag={pdfFlag} />
      ) : (
        <Viewer
          jsonLink={jsonLink}
          pdfLink={pdfLink}
          certId={certId}
          pdfFlag={pdfFlag}
        />
      )}
    </Container>
  );
};

export default ViewerManager;
