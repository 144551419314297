import React from 'react';
import '@blockcerts/blockcerts-verifier/dist/main';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Home: React.FC = () => {
  React.useEffect(() => {
    document.title = 'Home';
  });
  return (
    <Container>
      <blockcerts-verifier
        display-mode="full"
        disable-auto-verify
        show-metadata
        allow-download
        allow-social-share
      />
    </Container>
  );
};

export default Home;
