import styled from 'styled-components';

export const Button = styled.button`
  max-width: 50%;
  min-width: 20%;
  margin: 10px auto 0;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
  border: none;
`;
