import styled from 'styled-components';

export const Paragraph = styled.p`
  height: 100%;
  text-align: center;
  margin: 10px auto 0;
  min-height: 15px;
  font-size: 12px;
  color: red;
`;
