import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
`;
