import React from 'react';
import Viewer from './Viewer';
import { Container } from '../pages/Home';
import LoginForm from '../molecules/LoginForm';
import { ViewerQueryParam } from '../../types/ViewerQueryParam';
import httpClient from '../../api/client';

export type LoginProps = {
  pdfFlag: boolean;
} & ViewerQueryParam;

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const [authorized, setAuthorized] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [jsonLink, setJsonLink] = React.useState<string>('');
  const [pdfLink, setPdfLink] = React.useState<string>('');
  const [recaptchaToken, setRecaptchaToken] = React.useState<string>('');

  const genDownloadURLs = (sessionHash: string): void => {
    const URL: string = `session/${sessionHash}/issuer/${props.issuerId}/cert/${props.certId}`;

    setJsonLink(`${URL}/json`);
    setPdfLink(`${URL}/pdf`);
  };

  const sendAuthReq = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();
    const endpoint = `cert/${props.certId}`;
    const body = {
      password,
      recaptchaToken,
    };
    const init = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    type RequestBody = {
      password: string;
    };
    type ResponseBody = {
      session_id: string;
    };

    try {
      const res = await httpClient.post<RequestBody, ResponseBody>(
        endpoint,
        body,
        init,
      );

      setError(false);
      setAuthorized(true);
      genDownloadURLs(res.session_id);
    } catch (err) {
      setError(true);
      setErrorMessage('Wrong password');
      console.error(err);
    }
  };

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const eventValue = event.target.value;

    setPassword(eventValue);
  };

  const handleRecaptcha = (token: string | null): void => {
    if (token) {
      setRecaptchaToken(token);
    }
  };

  return (
    <Container>
      {!authorized ? (
        <LoginForm
          handleChange={changePassword}
          handleSubmit={sendAuthReq}
          handleRecaptcha={handleRecaptcha}
          error={error}
          errorMessage={errorMessage}
        />
      ) : (
        <Viewer
          jsonLink={jsonLink}
          pdfLink={pdfLink}
          certId={props.certId}
          pdfFlag={props.pdfFlag}
        />
      )}
    </Container>
  );
};

export default Login;
