import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '@blockcerts/blockcerts-verifier/dist/main';
import { OldQueryParam } from '../../../types/old/IViewer';

const OldViewer: FC = () => {
  const { id } = useParams<OldQueryParam>();
  const link: string = `https://issued-cloudcerts-stg.s3.us-east-2.amazonaws.com/${id}.json`;

  useEffect(() => {
    document.title = 'OldViewer';
  });

  return (
    <div className="container">
      <blockcerts-verifier
        display-mode="fullscreen"
        disable-auto-verify
        show-metadata
        allow-social-share
        src={link}
      />
    </div>
  );
};

export default OldViewer;
